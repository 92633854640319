export const TelegramIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.9365 4.98934L20.5734 19.5367C20.3197 20.5635 19.658 20.819 18.7178 20.3353L13.5936 16.8719L11.1211 19.0531C10.8474 19.304 10.6186 19.5139 10.0913 19.5139L10.4594 14.7272L19.9565 6.85568C20.3695 6.51801 19.867 6.33092 19.3148 6.66859L7.57394 13.4495L2.51941 11.9984C1.41995 11.6835 1.40005 10.9899 2.74825 10.5062L22.5186 3.52C23.434 3.20514 24.235 3.70709 23.9365 4.98934Z"
        fill="#245462"
      />
    </svg>
  );
};
