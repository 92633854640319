import { useRoutes } from 'react-router-dom';
import { CategoriesPage, Home, ProductDetail, SearchPage } from './pages';

export const Routes = () => {
  const routes = [
    { path: '/', element: <Home /> },
    { path: '/product/:id', element: <ProductDetail /> },
    { path: '/categories/:id', element: <CategoriesPage /> },
    {
      path: '/search',
      element: (
        <SearchPage searchParams={new URLSearchParams(window.location.search).get('query')} />
      ),
    },
  ];

  return useRoutes(routes);
};
