import styled from 'styled-components';

export const ContactInput = styled.input`
  background: #fcfcfc;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.07) inset;
  border: none;
  outline: none;
  padding: 12px;
  font-size: 16px;
  color: #686868;
  margin-bottom: 10px;
`;
