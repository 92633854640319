import styled from 'styled-components';

export const NavCategoryBox = styled.a`
  & path {
    transition: 0.3s ease;
    ${(props) => {
      if (props.isHover) {
        return `
          fill: white;
        `;
      }
    }}
  }

  & span {
    transition: 0.3s ease;
    ${(props) => {
      if (props.isHover) {
        return `
          color: white;
        `;
      }
    }}
  }

  &:hover {
    & path {
      fill: white;
    }

    & span {
      color: white;
    }
  }
`;
