import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './banner.css';

import banner_1 from '../../../assets/banner/banner_1.png';
import banner_2 from '../../../assets/banner/banner_2.png';
import banner_3 from '../../../assets/banner/banner_3.png';
import banner_4 from '../../../assets/banner/banner_4.png';

import { Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom';

export default function Banner() {
  const params = {
    autoplay: {
      delay: 2200,
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: '.button-next',
      prevEl: '.button-prev',
    },
    loop: true,
  };

  return (
    <div className="mt-0 pt-8 pb-10 max-sm:pb-5">
      <div className="container">
        <div className="banner banner-first">
          <div className="button-prev button-slider-banner">
            <i className="fa-regular fa-angle-left"></i>
          </div>
          <Swiper {...params} modules={[Navigation]} className="mySwiper">
            <SwiperSlide>
              <Link className={'w-full h-full'} to={`/categories/6537cf99060fda0108529697`}>
                <img src={banner_1} alt="banner 1" loading="lazy" />
              </Link>
            </SwiperSlide>
            <SwiperSlide>
              <Link className={'w-full h-full'} to={`/categories/6537cf99060fda0108529697`}>
                <img src={banner_2} alt="banner 1" loading="lazy" />
              </Link>
            </SwiperSlide>
            <SwiperSlide>
              <Link className={'w-full h-full'} to={`/categories/6537cf99060fda0108529697`}>
                <img src={banner_3} alt="banner 1" loading="lazy" />
              </Link>
            </SwiperSlide>
            <SwiperSlide>
              <Link className={'w-full h-full'} to={`/categories/6537cf99060fda0108529697`}>
                <img src={banner_4} alt="banner 1" loading="lazy" />
              </Link>
            </SwiperSlide>
          </Swiper>
          <div className="button-next button-slider-banner">
            <i className="fa-regular fa-angle-right"></i>
          </div>
        </div>
        <div className="banner-mobile banner">
          <Swiper {...params} modules={[Navigation]} className="mySwiper">
            <SwiperSlide>
              <Link className={'w-full h-full'} to={`/categories/6537cf99060fda0108529697`}>
                <img src={banner_1} alt="banner 1" loading="lazy" />
              </Link>
            </SwiperSlide>
            <SwiperSlide>
              <Link className={'w-full h-full'} to={`/categories/6537cf99060fda0108529697`}>
                <img src={banner_2} alt="banner 1" loading="lazy" />
              </Link>
            </SwiperSlide>
            <SwiperSlide>
              <Link className={'w-full h-full'} to={`/categories/6537cf99060fda0108529697`}>
                <img src={banner_3} alt="banner 1" loading="lazy" />
              </Link>
            </SwiperSlide>
            <SwiperSlide>
              <Link className={'w-full h-full'} to={`/categories/6537cf99060fda0108529697`}>
                <img src={banner_4} alt="banner 1" loading="lazy" />
              </Link>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
}
