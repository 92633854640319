import { ContactInput } from './detail.styles';
import toast from 'react-hot-toast';
import { axiosInstance } from '../../../api';
import { useState } from 'react';

export default function DetailContactModal({ productName, setIsOpenModal, productId }) {
  const [value, setValue] = useState({
    name: '',
    phone_number: '+998',
    product_id: '',
    description: '',
  });

  const handleChange = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const handleSend = async (e) => {
    e.preventDefault();
    await axiosInstance
      .post('/order', {
        name: value.name,
        phone_number: value.phone_number,
        product_id: productId,
        description: value.description,
      })
      .then((_) => {
        setIsOpenModal(false);
        toast.success('Ваш запрос отправлено!');
      })
      .catch((error) => {
        toast.error("Iltimos hamma malumotlarni to'liq to'ldiring");
      });
  };

  return (
    <div className={'flex flex-col max-[400px] w-[400px] max-md:w-full'}>
      <h1
        className={'text-[18px] font-[500] pb-[15px]'}
        style={{ borderBottom: '1px solid rgba(128, 128, 128, 0.23)' }}
      >
        Buyurtma - {productName}
      </h1>
      <form onSubmit={handleSend} className={'flex flex-col mt-[20px]'}>
        <ContactInput
          onChange={handleChange}
          name={'name'}
          value={value.name}
          placeholder={'Ism'}
          type="text"
        />
        <ContactInput
          onChange={handleChange}
          name={'phone_number'}
          value={value.phone_number}
          placeholder={'Telefon raqam'}
          type="text"
        />
        <ContactInput
          onChange={handleChange}
          name={'description'}
          value={value.description}
          placeholder={'Izoh'}
          type="text"
        />
        <span className={'text-[14px] pt-[10px] text-[gray]'}>
          Iltimos, siz bilan bog'lanishimiz uchun ismingiz va telefon raqamingizni kiriting
        </span>
        <div
          className={'flex mt-[20px] justify-end items-center'}
          style={{ borderTop: '1px solid rgba(128, 128, 128, 0.23)' }}
        >
          <button
            type={'button'}
            onClick={() => setIsOpenModal(false)}
            style={{ border: '1px solid #245462' }}
            className={
              'bg-[white] text-[#245462] py-[10px] px-[20px] mr-3 mt-[25px] hover:bg-[#24546221]'
            }
          >
            Yopish
          </button>
          <button
            type={'submit'}
            style={{ border: '1px solid #245462' }}
            className={'bg-[#245462] text-[white] py-[10px] px-[20px] mt-[25px] hover:bg-[#17363f]'}
          >
            Jo'natish
          </button>
        </div>
      </form>
    </div>
  );
}
