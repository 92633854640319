import BaseLayout from '../../layouts/base-layout';
import { useParams } from 'react-router-dom';
import { ProductDetailSection } from '../../sections';
import { useEffect, useState } from 'react';
import { axiosInstance, ProductAPI } from '../../api';
import { ComponentsLoader } from '../../components';

export default function ProductDetail() {
  const { id } = useParams();

  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);

  const getProduct = async () => {
    setLoader(true);
    await axiosInstance.get(`${ProductAPI.GET}/${id}`).then((res) => {
      setData(res.data);
    });
    setLoader(false);
  };

  useEffect(() => {
    getProduct();
  }, []);

  return (
    <BaseLayout>
      <div className="container">
        {loader ? <ComponentsLoader /> : <ProductDetailSection detail={data} />}
      </div>
    </BaseLayout>
  );
}
