import { useEffect, useState } from 'react';
import { axiosInstance } from '../../../../api';
import { ComponentsLoader } from '../../../../components';
import { Link } from 'react-router-dom';

export default function CategoryFilter() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const getCategories = async () => {
    setLoading(true);
    await axiosInstance.get('category').then((res) => {
      setData(res.data);
    });
    setLoading(false);
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <>
      <h3 className={'text-[16px] -mt-[5px]'}>Kategoriyalar</h3>
      {loading ? (
        <ComponentsLoader />
      ) : (
        <div className={'flex flex-col mt-3'}>
          {data.map((category) => (
            <Link
              to={`/categories/${category._id}`}
              key={category._id}
              className={'flex items-center bg-[#F8F8F8] cursor-pointer p-[10px] mb-[10px]'}
            >
              <span className={'text-[14px] text-[#686868]'}>{category.name}</span>
            </Link>
          ))}
        </div>
      )}
    </>
  );
}
