import React, { useEffect, useState } from 'react';
import {
  Siderbar,
  Close,
  Header,
  Content,
  CategoryButton,
  Icon,
  IconName,
  DownArrow,
  Cities,
  Split,
  CategoryList,
  CategoryLink,
} from './mobile-sidear.styles';
import MobileInnerBtn from './mobile-inner-btn/mobile-inner-btn';
import { CategoryIcon, ContactIcon, HomeIcon, TelegramIcon } from '../../../../icons';
import { CategoryData } from '../../../../data';
import { Link } from 'react-router-dom';
import { axiosInstance } from '../../../../api';

export default function MobileSidebar({ sModal, setSModal }) {
  const [active, setActive] = React.useState(false);
  const [activeLink, setActiveLink] = useState(null);
  const [data, setData] = useState([]);

  const getCategories = async () => {
    await axiosInstance.get('category').then((res) => {
      setData(res.data);
    });
  };

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (sModal) {
      document.body.classList.add('hide-scroll');
    } else {
      document.body.classList.remove('hide-scroll');
    }
  }, [sModal]);

  return (
    <Siderbar sModal={sModal}>
      <Header>
        <h3 className={'text-[#414141] font-[500] text-[14px]'}>Меню</h3>
        <Close onClick={() => setSModal(false)}>
          <i className="fa-solid fa-xmark"></i>
        </Close>
      </Header>
      <Cities onClick={() => setSModal(false)}>
        <MobileInnerBtn icon={<HomeIcon />} link="/" title="Главная" />
      </Cities>
      <Content>
        <CategoryButton onClick={() => setActive((prevState) => !prevState)}>
          <Icon>
            <CategoryIcon />
          </Icon>
          <IconName>Категории</IconName>
          <DownArrow active={active}>
            <i className="fa-regular fa-angle-down"></i>
          </DownArrow>
        </CategoryButton>
        {active && <Split />}
        <CategoryList active={active}>
          {data.map((list) => (
            <div>
              <CategoryLink
                id={list._id}
                onClick={() => {
                  setActiveLink(list._id);
                }}
              >
                <MobileInnerBtn child active={list._id === activeLink} title={list?.name} />
              </CategoryLink>
              <CategoryList active={list._id === activeLink}>
                {list?.children?.map((sub) => (
                  <CategoryLink
                    className={'bg-white px-[25px]'}
                    id={sub._id}
                    onClick={() => setSModal(false)}
                  >
                    <Link to={`/categories/${sub._id}`} className={'text-[13px] py-2 block'}>
                      {sub?.name}
                    </Link>
                    <Split />
                  </CategoryLink>
                ))}
              </CategoryList>
            </div>
          ))}
        </CategoryList>
        <Cities onClick={() => setSModal(false)}>
          <MobileInnerBtn icon={<ContactIcon />} link="/" title="Контакты" />
          <Split />
          <MobileInnerBtn
            icon={<TelegramIcon />}
            link="https://t.me/apezon"
            title="Contact with us"
            target="_blank"
          />
        </Cities>
      </Content>
    </Siderbar>
  );
}
