import { ProductStyles } from './product.styles';
import { Link } from 'react-router-dom';

export default function Product({ data }) {
  console.log(data);

  return (
    <Link to={`/product/${data._id}`}>
      <ProductStyles className={'p-[35px] cursor-pointer max-md:p-[15px]'}>
        <img
          className={'h-[150px] w-full object-contain'}
          src={data.color[0].images[0].url}
          alt={data.title}
        />
        <h3 className={'pt-[55px] text-[16px] font-[500] max-lg-phone:pt-[25px]'}>{data.title}</h3>
      </ProductStyles>
    </Link>
  );
}
