import { classNames } from '../../../../utility/className';
import { useEffect, useState } from 'react';
import { NavCategoryBox } from './navbar-bottom.styles';
import { Link } from 'react-router-dom';
import { axiosInstance } from '../../../../api';

export default function NavbarBottom() {
  const [hoveredCategory, setHoveredCategory] = useState(null);

  const [data, setData] = useState([]);

  const getCategories = async () => {
    await axiosInstance.get('category').then((res) => {
      setData(res.data);
    });
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <div className={'container relative max-md:hidden'}>
      <div className={'navbar-categories mt-[20px] flex items-center'}>
        {data.map((category) => (
          <div
            key={category._id}
            onMouseEnter={() => setHoveredCategory(category._id)}
            onMouseLeave={() => setHoveredCategory(null)}
          >
            <NavCategoryBox
              as={Link}
              to={`/categories/${category._id}`}
              style={{ transition: '0.3s ease' }}
              className={classNames(
                hoveredCategory === category._id ? 'bg-[#245462]' : 'bg-[white]',
                'flex cursor-pointer items-center px-[40px] py-[25px]'
              )}
              isHover={hoveredCategory === category._id}
            >
              <span className={'text-[16px] text-[#414141] whitespace-nowrap'}>
                {category.name}
              </span>
            </NavCategoryBox>
            {category?.children.length !== 0 && (
              <div
                style={{ transition: '0.3s ease' }}
                className={classNames(
                  hoveredCategory === category._id ? 'opacity-100 visible' : 'opacity-0 invisible',
                  'nav-sub-categories z-[5] max-xl:hidden p-5 w-[200px] h-[auto] flex flex-col absolute'
                )}
              >
                {category?.children?.map((sub, index) => (
                  <Link
                    to={`/categories/${sub._id}`}
                    key={index}
                    className={'text-[14px] cursor-pointer font-[500] my-2 text-[#414141]'}
                  >
                    {sub?.name}
                  </Link>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
