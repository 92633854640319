import { Link } from 'react-router-dom';

export default function NavbarTop() {
  return (
    <div className={'bg-[#313131]'}>
      <div className="container">
        <div className={'flex h-[38px] max-md:justify-center justify-between items-center'}>
          <div className={'max-md:hidden'}>
            <Link className={'text-[14px] text-white mr-5'} to={'/'}>
              Bosh sahifa
            </Link>
            <a
              className={'text-[14px] text-white'}
              target={'_blank'}
              href={'https://t.me/ZILOLMEBEL_UZ'}
            >
              Biz bilan bog'lanish
            </a>
          </div>
          <a className={'text-[14px] tracking-[0.5px] text-white'} href={'tel:+998 91 360 06 66'}>
            +998 91 360 06 66
          </a>
        </div>
      </div>
    </div>
  );
}
