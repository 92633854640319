import { useEffect, useState } from 'react';
import { Fancybox } from '../../../components/full-screen-image/fancybox';
import { classNames } from '../../../utility/className';
import { SliderImages } from './slider-images';

export function DetailImages({ data, currentColor }) {
  const [currentImg, setCurrentImg] = useState(0);

  const filteredData = data?.color?.filter((c) => c.color._id === currentColor);

  return (
    <div className={'sticky top-5'}>
      <Fancybox
        options={{
          Carousel: {
            infinite: false,
          },
        }}
      >
        {filteredData?.map((imgData) =>
          imgData.images?.map((image, index) => (
            <a data-fancybox="gallery" key={index} href={image.url}>
              {index === currentImg && (
                <img
                  className={classNames(
                    'object-contain product-img-big w-full rounded-[10px] duration-500 ease-in-out'
                  )}
                  src={image.url}
                  alt={'images'}
                />
              )}
            </a>
          ))
        )}
      </Fancybox>
      <SliderImages images={filteredData} currentImg={currentImg} setCurrentImg={setCurrentImg} />
    </div>
  );
}
