import { CategoriesFilterSide } from '../../sections';
import { ComponentsLoader, Products } from '../../components';
import BaseLayout from '../../layouts/base-layout';
import { useEffect, useState } from 'react';
import { axiosInstance, ProductAPI } from '../../api';
import { useParams } from 'react-router-dom';

export default function CategoriesPage() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [colors, setColors] = useState([]);
  const { id } = useParams();

  const getProducts = async () => {
    setLoading(true);
    const colorParams = colors.map((color) => `color=${color}`).join('&');
    const url = `${ProductAPI.GET}?categoryId=${id}&${colorParams}`;

    await axiosInstance.get(url).then((res) => {
      setData(res.data.data);
    });
    setLoading(false);
  };

  useEffect(() => {
    if (window.innerWidth >= 768) {
      getProducts();
    }
  }, [id, colors]);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      getProducts();
    }
  }, [id]);

  const handleFilter = () => {
    getProducts();
  };

  return (
    <BaseLayout>
      <div className="container">
        <div className={'flex my-10'}>
          <CategoriesFilterSide colors={colors} setColors={setColors} />
          <div className={'w-full'}>
            {loading ? (
              <div className={'flex justify-center w-full'}>
                <ComponentsLoader />
              </div>
            ) : (
              <Products
                handleFilter={handleFilter}
                data={data}
                colors={colors}
                setColors={setColors}
                isCategoryPage
              />
            )}
          </div>
        </div>
      </div>
    </BaseLayout>
  );
}
