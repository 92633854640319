export default function ContactIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 8C11 9.65685 9.65685 11 8 11C6.34315 11 5 9.65685 5 8C5 6.34315 6.34315 5 8 5C9.65685 5 11 6.34315 11 8ZM11 8V9C11 10.1046 11.8954 11 13 11C13.7095 11 14.3328 10.6305 14.6878 10.0734C14.8855 9.76329 15 9.39502 15 9V8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C9.48725 15 10.8662 14.5362 12 13.7453"
        stroke="#245462"
      />
    </svg>
  );
}
