import React from 'react';
import { DownArrow, InnerBtn, InnerBtnText } from '../mobile-sidear.styles';
import { Link } from 'react-router-dom';

function MobileInnerBtn({ active, child, link, title, target, onClick }) {
  return (
    <InnerBtn onClick={onClick}>
      <InnerBtnText>
        <Link to={link} target={target}>
          {title}
        </Link>
      </InnerBtnText>
      {child && (
        <DownArrow active={active}>
          <i className="fa-regular fa-angle-down"></i>
        </DownArrow>
      )}
    </InnerBtn>
  );
}

export default MobileInnerBtn;
