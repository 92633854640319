import { useState, useEffect } from 'react';
import { SearchIcon } from '../../../../../icons';
import { classNames } from '../../../../../utility/className';
import { Link, useNavigate } from 'react-router-dom';
import { axiosInstance, ProductAPI } from '../../../../../api';

export default function SearchBar({ isMobile }) {
  const [isFocused, setIsFocused] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [recentlySearchText, setRecentlySearchText] = useState([]);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  useEffect(() => {
    const storedRecentlySearchText = localStorage.getItem('recentlySearchText');
    if (storedRecentlySearchText) {
      setRecentlySearchText(JSON.parse(storedRecentlySearchText));
    }
  }, []);

  useEffect(() => {
    if (searchText === '') {
      setData([]);
    }
  }, [searchText]);

  window.addEventListener('click', () => {
    setIsFocused(false);
  });

  const handleFilterSearchProducts = async () => {
    if (searchText) {
      await axiosInstance.get(`${ProductAPI.GET}?search=${searchText}&size=5`).then((res) => {
        setData(res.data.data);
      });
    } else {
      setData([]);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    navigate(`/search?query=${searchText}`);
    setIsFocused(false);
    const updatedRecentlySearchText = [{ text: searchText }, ...recentlySearchText];
    const last5Searches = updatedRecentlySearchText.slice(0, 5);
    setRecentlySearchText(last5Searches);
    localStorage.setItem('recentlySearchText', JSON.stringify(last5Searches));
  };

  const handleRemoveSearchItem = (e, itemIndex) => {
    e.stopPropagation();
    const updatedRecentlySearchText = [...recentlySearchText];
    updatedRecentlySearchText.splice(itemIndex, 1);
    setRecentlySearchText(updatedRecentlySearchText);
    localStorage.setItem('recentlySearchText', JSON.stringify(updatedRecentlySearchText));
  };

  return (
    <div
      className={classNames(
        isMobile ? 'w-[100%] hidden max-md:block mt-3' : 'w-[90%] max-md:hidden',
        'relative'
      )}
    >
      <SearchIcon className={'absolute left-[16px] top-[16px]'} />
      <form onSubmit={handleSearch}>
        <input
          className={
            'border-[1px] p-[5px] search-bar pl-[45px] py-[12px] w-[100%] border-solid border-[grey] outline-none'
          }
          placeholder={'Mebel qidirish...'}
          onChange={(e) => {
            setSearchText(e.target.value);
            handleFilterSearchProducts();
          }}
          value={searchText}
          onFocus={handleInputFocus}
          onClick={(e) => e.stopPropagation()}
        />
      </form>
      <div
        style={{ transition: '0.1s ease' }}
        className={classNames(
          isFocused ? 'visible opacity-[1]' : 'invisible opacity-[0]',
          'absolute z-[2] search-bar-modal rounded-[10px] p-[15px] top-[50px] left-0 w-full bg-white'
        )}
      >
        {data.length >= 1 && (
          <>
            <h2 className={'text-[18px] font-[600] mb-[6px]'}>Maxsulotlar</h2>
            <ul className={'mb-5'}>
              {data?.map((rec, key) => (
                <Link
                  to={`/search?query=${rec?.title}`}
                  key={key}
                  className={
                    'flex items-center mt-[4px] cursor-pointer hover:bg-[#41414111] rounded-[5px] p-[5px]'
                  }
                >
                  <SearchIcon className={'mr-[8px]'} /> {rec?.title}
                </Link>
              ))}
            </ul>
          </>
        )}
        {searchText === '' && recentlySearchText.length >= 1 && (
          <>
            <h2 className={'text-[18px] font-[600] mb-[6px]'}>Last search</h2>
            <ul className={'mb-5'}>
              {recentlySearchText?.map((rec, key) => (
                <li
                  key={key}
                  className={
                    'flex justify-between items-center mt-[4px] cursor-pointer hover:bg-[#41414111] rounded-[5px] p-[5px]'
                  }
                >
                  <span className={'flex items-center'}>
                    <SearchIcon className={'mr-[8px]'} /> {rec.text}
                  </span>
                  <i
                    className="fa-solid fa-xmark text-[gray] cursor-pointer"
                    onClick={(e) => handleRemoveSearchItem(e, key)}
                  ></i>
                </li>
              ))}
            </ul>
          </>
        )}
        {searchText === '' && (
          <>
            <h2 className={'text-[18px] font-[600] mb-[6px]'}>Popular</h2>
            <ul>
              <li
                className={
                  'flex items-center mt-[4px] cursor-pointer hover-bg-[#41414111] rounded-[5px] p-[5px]'
                }
              >
                <SearchIcon className={'mr-[8px]'} /> Clothes
              </li>
              <li
                className={
                  'flex items-center mt-[4px] cursor-pointer hover-bg-[#41414111] rounded-[5px] p-[5px]'
                }
              >
                <SearchIcon className={'mr-[8px]'} /> Shoes
              </li>
              <li
                className={
                  'flex items-center mt-[4px] cursor-pointer hover-bg-[#41414111] rounded-[5px] p-[5px]'
                }
              >
                <SearchIcon className={'mr-[8px]'} /> Accessories
              </li>
            </ul>
          </>
        )}
      </div>
    </div>
  );
}
