import CategoryFilter from './components/category-filter';
import ColorFilter from './components/color-filter';

export default function CategoryFilterSide({ setColors, colors }) {
  return (
    <div className={'sticky top-[20px] h-full max-md:hidden'}>
      <div className={'category-filter-side max-w-[300px] w-[300px] max-lg:w-[250px] mr-5 p-5'}>
        <CategoryFilter />
        <ColorFilter setColors={setColors} colors={colors} />
      </div>
    </div>
  );
}
