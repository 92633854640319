import React from 'react';
import styled from 'styled-components';

export const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 5px;
`;

export const HiddenCheckbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

export const StyledCheckbox = styled.span`
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  transition: 0.2s;
  position: relative;

  ${(props) => {
    if (props.bg) {
      return `
        border: 2px solid ${props.bg};
      `;
    }
  }}

  ${HiddenCheckbox}:checked + & {
    background-color: ${(props) => props.bg};
  }

  &::before {
    content: '\u2713'; /* Unicode checkmark character */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    opacity: 0;
    color: #fff; // Color of the checkmark
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${HiddenCheckbox}:checked + &::before {
    opacity: 1;
  }
`;

export const CheckboxLabel = styled.span`
  margin-left: 0.5rem;
  font-size: 15px;
  user-select: none;
`;

export default function CustomCheckbox({ label, checked, bg, onChange }) {
  return (
    <CheckboxContainer>
      <HiddenCheckbox type="checkbox" checked={checked} onChange={onChange} />
      <StyledCheckbox bg={bg} />
      <CheckboxLabel>{label}</CheckboxLabel>
    </CheckboxContainer>
  );
}
