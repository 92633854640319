import styled from 'styled-components';

export const MobileFilterMenuStyles = styled.div`
  position: fixed;
  top: 0;
  right: -100%;
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  background: #fff;
  text-align: left;
  z-index: 999;
  transition: 0.3s ease;
  padding: 20px;
  color: rgb(3, 81, 171);

  ${(props) => {
    if (props.isOpen) {
      return `
            right: 0
          `;
    }
  }}
`;
