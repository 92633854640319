import SearchBar from './components/search-bar';
import logo from '../../../../assets/logo-2.png';
import { Link } from 'react-router-dom';
import MobileSidebar from '../mobile-sidebar/mobile-sidebar';
import { useState } from 'react';
import { HamburgerIcon } from '../../../../icons';
export default function NavbarMiddle() {
  const [sModal, setSModal] = useState(false);

  return (
    <div className={'container'}>
      <div className={'flex mt-5 justify-between items-center'}>
        <Link to={'/'} className={'mr-10 flex items-center'}>
          <img className={'w-[45px]'} src={logo} alt={'Zilol Mebel'} />
          <h1 className={'text-[22px] font-bold ml-3'}>ZilolMebel</h1>
        </Link>
        <SearchBar />
        <button onClick={() => setSModal(true)} className={'hidden max-md:block'}>
          <HamburgerIcon />
        </button>
      </div>
      <SearchBar isMobile />
      <MobileSidebar setSModal={setSModal} sModal={sModal} />
    </div>
  );
}
