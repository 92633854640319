import { DetailImages } from './components/DetailImages';
import { DetailInfo } from './components/DetailInfo';
import { useEffect, useState } from 'react';

export default function ProductDetailSection({ detail }) {
  const [currentColor, setCurrentColor] = useState();

  useEffect(() => {
    if (detail?.product?.color?.length >= 1) {
      setCurrentColor(detail?.product?.color[0]?.color?._id);
    }
  }, [detail]);

  return (
    <div className={'grid mt-16 grid-cols-2 mb-14 max-md:grid-cols-1'}>
      <div>
        <DetailImages data={detail?.product} currentColor={currentColor} />
      </div>
      <div>
        <DetailInfo data={detail} setCurrentColor={setCurrentColor} currentColor={currentColor} />
      </div>
    </div>
  );
}
