import React, { useEffect, useState } from 'react';
import { axiosInstance } from '../../../../api';
import CustomCheckbox from './custom-checkbox/custom-checkbox';
import { classNames } from '../../../../utility/className';

export default function ColorFilter({ colors, setColors, isMenu, handleFilter }) {
  const [data, setData] = useState([]);

  const getColors = async () => {
    await axiosInstance
      .get('/color/list')
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getColors();
  }, []);

  const handleCheckboxChange = (colorId, checked) => {
    if (checked) {
      setColors([...colors, colorId]);
    } else {
      setColors(colors.filter((id) => id !== colorId));
    }
  };

  const handleClearFilter = () => {
    handleFilter();
    setColors([]);
  };

  return (
    <div className={!isMenu && 'mt-3'}>
      <h3 className={classNames(isMenu ? 'text-[18px]' : 'text-[16px]', '-mt-[5px]')}>
        {isMenu ? "Ranglar bo'yichi filter" : 'Ranglar'}
      </h3>
      <div className="flex flex-col mt-3">
        {data.map((color) => (
          <CustomCheckbox
            key={color._id}
            bg={color?.color}
            label={color.name}
            checked={colors?.includes(color?._id)}
            onChange={(event) => handleCheckboxChange(color?._id, event.target.checked)}
          />
        ))}
      </div>
      <div className={'mt-3'}>
        {isMenu && (
          <button
            onClick={handleFilter}
            style={{ transition: '0.3s ease' }}
            className={
              'px-[12px] mr-3 py-[6px] hover:bg-[#0351ABFF] text-[14px] rounded-[5px] bg-[#078DEEFF] text-[white]'
            }
          >
            Qidirish
          </button>
        )}
        <button
          onClick={handleClearFilter}
          className={'mt-2 text-[gray] text-[15px] cursor-pointer hover:underline'}
        >
          Filterni o'chirish
        </button>
      </div>
    </div>
  );
}
