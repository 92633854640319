import logo from '../../../assets/logo-2.png';
import { InstagramIcon, TelegramIcon } from '../../../icons';
import { Link } from 'react-router-dom';

export default function FooterMain() {
  return (
    <div className={'flex flex-wrap justify-between items-center mb-[50px]'}>
      <div>
        <div className={'mr-10'}>
          <Link to={'/'} className={'mr-10 flex items-center'}>
            <img className={'w-[70px]'} src={logo} alt={'Zilol Mebel'} />
            <h1 className={'text-[30px] font-bold ml-3'}>ZilolMebel</h1>
          </Link>
        </div>
        <a className={'inline-block text-[23px] mt-3 font-bold'} href="tel:+998 91 360 06 66">
          +998 91 360 06 66
        </a>{' '}
        <br />
        <a className={'inline-block text-[23px] mb-3 font-bold'} href="tel:+998 91 359 06 66">
          +998 91 359 06 66
        </a>
        <div className={'flex items-center pt-2'}>
          <a
            className="h-[40px] w-[40px] footer-icon mr-3"
            href="https://t.me/ZILOLMEBEL_UZ"
            target="_blank"
          >
            <TelegramIcon />
          </a>
          <a
            className="h-[40px] w-[40px] footer-icon"
            href="https://www.instagram.com/zilol_mebel/"
            target="_blank"
          >
            <InstagramIcon />
          </a>
        </div>
      </div>
      <div className={'flex flex-col mx-5 footer-navigation'}>
        <span className={'text-[16px] pb-2 font-[500]'}>Kategoriyalar</span>
        <a className={'text-[16px] pb-1'} href="#">
          Кухни
        </a>
        <a className={'text-[16px] pb-1'} href="#">
          Спальни
        </a>
        <a className={'text-[16px] pb-1'} href="#">
          Гостинные
        </a>
        <a className={'text-[16px] pb-1'} href="#">
          Шкафы
        </a>
      </div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3011.1920370625844!2d71.5995925!3d40.9991698!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38bb4a408f6449d5%3A0x18eedf562ff51ddf!2sZILOL%20MEBEL!5e0!3m2!1sen!2s!4v1698832908692!5m2!1sen!2s"
        width="400"
        height="200"
        style={{ border: 0 }}
        allowFullScreen=""
        className={'max-lg:mt-10 max-lg:w-full'}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
}
