import BaseLayout from '../../layouts/base-layout';
import { Banner } from '../../sections';
import { Products } from '../../components';
import { ProductData } from '../../data';
import { useEffect, useState } from 'react';
import { axiosInstance, ProductAPI } from '../../api';

export default function Home() {
  const [data, setData] = useState([]);

  const getProducts = async () => {
    await axiosInstance.get(ProductAPI.GET).then((res) => {
      setData(res.data.data);
      console.log(res.data.data);
    });
  };

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <BaseLayout>
      <Banner />
      <Products data={data} />
    </BaseLayout>
  );
}
