import FooterMain from './components/footer-main';
import FooterCopyright from './components/footer-copyright';

export default function Footer() {
  return (
    <div className={'footer pt-[30px]'}>
      <div className={'container'}>
        <FooterMain />
        <FooterCopyright />
      </div>
    </div>
  );
}
