import styled from 'styled-components';

export const ProductStyles = styled.div`
  transition: 0.3s ease;
  box-shadow: 0px 1px 9px 0px #0000001c;

  &:hover {
    box-shadow: 0px 1px 9px 0px #0000001c;
    transform: scale(1.02);
  }

  @media screen and (max-width: 425px) {
    & img {
      height: 120px;
    }
    & h3 {
      padding-top: 25px;
      font-size: 14px;
    }
  }

  @media screen and (max-width: 350px) {
    & img {
      height: 150px;
    }
  }
`;
