import { useEffect, useState } from 'react';
import { ColorRadio } from './color-radion';
import { Modal } from '../../../components';
import DetailContactModal from './detail-contact-modal';

export function DetailInfo({ data, setCurrentColor, currentColor }) {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleColorChange = (event, color) => {
    setCurrentColor(color);
  };

  const product = data?.product;
  const options = data?.options;

  return (
    <>
      <div className={'pl-[30px] sticky top-5 max-md:pl-0 max-md:mt-10 max-md:mb-5'}>
        <h1 className={'text-[#111827FF] text-3xl font-bold max-lg:text-2xl'}>{product?.title}</h1>
        <p className={'text-[1rem] text-[#111827FF] font-light mt-6 max-lg:text-[.8rem]'}>
          {product?.description}
        </p>
        <div className={'mt-5'}>
          <span className={' mb-2 text-[#111827FF] font-medium text-[15px] block'}>Rang</span>
          {product?.color?.map((color) => (
            <ColorRadio
              key={color.color._id}
              color={color.color.color}
              checked={currentColor === color.color._id}
              onChange={(e) => handleColorChange(e, color.color._id)}
            />
          ))}
        </div>
        <div className="mt-5">
          {options?.length !== 0 && (
            <span className={' mb-1 text-[#111827FF] font-[600] text-[16px] block'}>
              Qo&apos;shimcha ma&apos;lumotlar
            </span>
          )}
          {options?.map((option) => (
            <div key={option?._id} className="flex items-center pb-[4px]">
              <p className="text-[14px] font-[500]">{option?.name.trim()}:</p>
              <p className="pl-[4px] text-gray-700 text-[14px]">{option?.value}</p>
            </div>
          ))}
        </div>
        <button
          onClick={() => setIsOpenModal(true)}
          style={{ transition: '0.3s ease' }}
          className={'bg-[#245462] text-[white] py-[12px] px-[50px] mt-[25px] hover:bg-[#17363f]'}
        >
          Sotib olish
        </button>
      </div>
      <Modal
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        Elements={
          <DetailContactModal
            productId={product?._id}
            productName={product?.title}
            setIsOpenModal={setIsOpenModal}
          />
        }
      />
    </>
  );
}
