import styled from 'styled-components';

export const ModalStyles = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(77, 92, 116, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5000;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s ease;

  ${(props) => {
    if (props.isOpen) {
      return `
            visibility: visible;
            opacity: 1;
         `;
    }
  }}
`;

export const ModalContent = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s ease;
  transform: scale(0.5);
  margin: 0 10px;
  z-index: 50001;

  ${(props) => {
    if (props.isOpen) {
      return `
            visibility: visible;
            opacity: 1;
            transform: scale(1);
         `;
    }
  }};

  @media screen and (max-width: 425px) {
    width: 350px;
  }
`;
