import { Product } from '../index';
import { ProductsStyles } from './products.styles';
import { classNames } from '../../utility/className';
import penguin from '../../assets/penguin.webp';
import { FilterIcon } from '../../icons';
import MobileFilterMenu from './components/mobile-filter-menu';
import { useState } from 'react';

export default function Products({ data, isCategoryPage, colors, setColors, handleFilter }) {
  const [isOpen, setIsOpen] = useState();

  return (
    <div className={!isCategoryPage && 'my-5'}>
      <div className={!isCategoryPage && 'container'}>
        {isCategoryPage && (
          <div className={'justify-end mb-8 hidden max-md:flex'}>
            <button className={'flex items-center'} onClick={() => setIsOpen(true)}>
              <FilterIcon /> <span className={'ml-2 text-[15px]'}>Filter</span>
            </button>
          </div>
        )}
        {data.length === 0 ? (
          <div className={'text-center flex flex-col mt-20 items-center'}>
            <img className={'w-[150px]'} src={penguin} alt={'no data'} />
            <h3 className={'text-[20px] font-bold mt-3'}>Maxsulotlar topilmadi (</h3>
          </div>
        ) : (
          <ProductsStyles
            className={classNames(
              isCategoryPage
                ? 'grid-cols-3 max-xl:gap-[10px] max-lg:grid-cols-2'
                : 'grid-cols-4 max-lg:grid-cols-3 max-sm:grid-cols-2',
              'grid gap-[20px] max-sm:gap-[15px]'
            )}
          >
            {data.map((res) => (
              <Product key={res.id} data={res} />
            ))}
          </ProductsStyles>
        )}
      </div>
      <div className={'hidden max-md:block'}>
        <MobileFilterMenu
          handleFilter={handleFilter}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          colors={colors}
          setColors={setColors}
        />
      </div>
    </div>
  );
}
