export default function HomeIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1L1 7V14C1 14.5523 1.44772 15 2 15H6V12C6 10.8954 6.89543 10 8 10C9.10457 10 10 10.8954 10 12V15H14C14.5523 15 15 14.5523 15 14V7L8 1Z"
        fill="#245462"
      />
      <path
        d="M8 1L8.3254 0.620372C8.13815 0.459876 7.86185 0.459876 7.6746 0.620372L8 1ZM1 7L0.674604 6.62037L0.5 6.77003V7H1ZM6 15V15.5C6.27614 15.5 6.5 15.2761 6.5 15H6ZM10 15H9.5C9.5 15.2761 9.72386 15.5 10 15.5V15ZM15 7H15.5V6.77003L15.3254 6.62037L15 7ZM2 15.5H6V14.5H2V15.5ZM15.3254 6.62037L8.3254 0.620372L7.6746 1.37963L14.6746 7.37963L15.3254 6.62037ZM7.6746 0.620372L0.674604 6.62037L1.3254 7.37963L8.3254 1.37963L7.6746 0.620372ZM6.5 15V12H5.5V15H6.5ZM9.5 12V15H10.5V12H9.5ZM10 15.5H14V14.5H10V15.5ZM15.5 14V7H14.5V14H15.5ZM0.5 7V14H1.5V7H0.5ZM8 10.5C8.82843 10.5 9.5 11.1716 9.5 12H10.5C10.5 10.6193 9.38071 9.5 8 9.5V10.5ZM8 9.5C6.61929 9.5 5.5 10.6193 5.5 12H6.5C6.5 11.1716 7.17157 10.5 8 10.5V9.5ZM14 15.5C14.8284 15.5 15.5 14.8284 15.5 14H14.5C14.5 14.2761 14.2761 14.5 14 14.5V15.5ZM2 14.5C1.72386 14.5 1.5 14.2761 1.5 14H0.5C0.5 14.8284 1.17157 15.5 2 15.5V14.5Z"
        fill="white"
      />
    </svg>
  );
}
