export default function FooterCopyright() {
  return (
    <div
      className={'flex justify-between footer-copyright flex-wrap items-center py-[25px]'}
      style={{ borderTop: '1px solid #F1F3F8' }}
    >
      <span className={'text-[17px] mr-3'}>
        Barcha huquqlar himoyalangan &copy; {new Date().getFullYear()} ZilolMebel
      </span>
      <span className={'text-[17px]'}>
        <span className={'text-[#ACB4BF]'}></span>{' '}
        <a href={'https://ideaone.uz'} target={'_blank'} className={'font-bold'}>
          Idea One Group
        </a>{' '}
        tomonidan ishlab chiqildi
      </span>
    </div>
  );
}
