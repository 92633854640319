import ColorFilter from '../../../sections/categories-page/category-filter-side/components/color-filter';
import { MobileFilterMenuStyles } from './mobile-filter-menu.styles';

export default function MobileFilterMenu({ colors, setColors, isOpen, handleFilter, setIsOpen }) {
  return (
    <MobileFilterMenuStyles isOpen={isOpen}>
      <div className={'mb-4'} onClick={() => setIsOpen(false)}>
        <i className="fa-solid text-[20px] fa-xmark"></i>
      </div>
      <ColorFilter handleFilter={handleFilter} isMenu colors={colors} setColors={setColors} />
    </MobileFilterMenuStyles>
  );
}
