import { ModalContent, ModalStyles } from './modal.styles';
import React, { useEffect } from 'react';

export default function Modal({ isOpen, setIsOpen, Elements }) {
  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    };

    if (isOpen) {
      document.body.classList.add('hide-scroll');
    } else {
      document.body.classList.remove('hide-scroll');
    }

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [isOpen]);

  return (
    <ModalStyles isOpen={isOpen} onClick={handleClose}>
      <ModalContent isOpen={isOpen} onClick={(e) => e.stopPropagation()}>
        {Elements}
      </ModalContent>
    </ModalStyles>
  );
}
