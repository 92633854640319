import NavbarTop from './components/navbar-top/navbar-top';
import NavbarMiddle from './components/navbar-middle/navbar-middle';
import NavbarBottom from './components/navbar-bottom/navbar-bottom';

export default function Navbar() {
  return (
    <>
      <NavbarTop />
      <NavbarMiddle />
      <NavbarBottom />
    </>
  );
}
