import React from 'react';

export function ColorRadio({ color, checked, onChange }) {
  const adjustedColor = color === '#ffffff' ? '#374151' : color;
  const adjustedColorBorder = color === '#ffffff';

  return (
    <label className={`color-radio`} style={checked ? { borderColor: adjustedColor } : {}}>
      <input type="radio" value={color} checked={checked} onChange={onChange} />
      <span
        className={`color-dot ${checked ? `outline-[${color}]` : ''}`}
        style={{ backgroundColor: color, border: '1px solid #c1c1c1' }}
      ></span>
    </label>
  );
}
