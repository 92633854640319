import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { classNames } from '../../../utility/className';

import { Navigation, Pagination } from 'swiper/modules';

export function SliderImages({ images, setCurrentImg, currentImg }) {
  const params = {
    navigation: {
      nextEl: '.button-next-img',
      prevEl: '.button-prev-img',
    },
  };

  const [slidesPerView, setSlidesPerView] = useState(4);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 540 && window.innerWidth >= 381) {
        setSlidesPerView(3);
      } else if (window.innerWidth <= 380) {
        setSlidesPerView(2);
      } else {
        setSlidesPerView(4);
      }
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={'mt-5 slider-images flex items-center'}>
      <div className="button-prev-img button-slider-banner">
        <i className="fa-regular fa-angle-left"></i>
      </div>
      <Swiper
        slidesPerView={slidesPerView}
        navigation={true}
        spaceBetween={30}
        modules={[Navigation, Pagination]}
        className="mySwiper productSwiper"
        {...params}
      >
        {images?.map((imgData) =>
          imgData?.images.map((img, index) => (
            <SwiperSlide key={index}>
              {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
              <img
                className={classNames(
                  index === currentImg ? 'border-[#5e8cfc]' : 'border-gray-100',
                  'border-solid border-[3px] duration-700 ease-in-out object-cover h-[6.5rem] rounded-[5px] cursor-pointer',
                )}
                src={img.url}
                alt={'Image'}
                onClick={() => setCurrentImg(index)}
              />
            </SwiperSlide>
          )),
        )}
      </Swiper>
      <div className="button-next-img button-slider-banner">
        <i className="fa-regular fa-angle-right"></i>
      </div>
    </div>
  );
}
